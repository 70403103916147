<template>
    <default-template>
        <v-layout class="fill-height justify-center align-center background-content">
            <v-responsive width="100vw" :min-height="$vuetify.breakpoint.mobile ? '83vh' : '50vh'" class="overflow-visible">
                <v-card tile elevation="0" class="subvisual-pa0">
                    <v-card-title class="headline d-block pa-0">
                        <div v-if="-1 < $route.path.indexOf('/center/notifications')" class="">
                            <div class="sub-visual-container sv2">
                                <div class="sub-visual-wrapper">
                                    <h1 class="sub-visual--title">공지사항</h1>
                                </div>
                            </div>
                        </div>
                        <div v-if="-1 < $route.path.indexOf('/center/faqs')" class="">
                            <div class="sub-visual-container sv3">
                                <div class="sub-visual-wrapper">
                                    <h1 class="sub-visual--title">자주묻는질문</h1>
                                </div>
                            </div>
                        </div>
                        <div v-if="-1 < $route.path.indexOf('/board/question')" class="">
                            <div class="sub-visual-container sv4">
                                <div class="sub-visual-wrapper">
                                    <h1 class="sub-visual--title">문의하기</h1>
                                </div>
                            </div>
                        </div>
                    </v-card-title>

                    <!-- <v-divider/> -->

                    <!-- <v-img src="/images/center/center-banner.png" /> -->
                    <!-- <v-card-text>
                        <div class="center-banner-top">
                            <h4><span>상품권 구입</span> 및 <span>결제오류</span> 상담은</h4>
                            <p>아래 게시판에 문의주시면 빠른 답변드리겠습니다.</p>
                        </div>
                        <div class="center-banner-bottom">
                            <ul>
                                <li class="banner-bottom__red">
                                    <p>대표번호</p>
                                    <h5>010-4122-0080</h5>
                                </li>
                                <li class="banner-bottom__yellow">
                                    <p>담당자</p>
                                    <h5>010-4122-0080</h5>
                                </li>
                                <li class="banner-bottom__red">
                                    <v-btn :href="'http://qr.kakao.com/talk/pvuBlLraz78iekdCjhrPjriJ2TU-'" target="_blank">
                                        <h6>카톡 상담</h6>
                                    </v-btn>
                                </li>
                            </ul>
                        </div>
                    </v-card-text> -->

                    <!-- <v-divider class="mt-4" /> -->
                    <!-- <v-card-text>
                        <v-btn-toggle tile class="d-flex">
                            <v-flex xs6 class="v-btn px-0"><v-btn to="/center/notifications" :color="-1 < $route.path.indexOf('/center/notifications') ? 'primary': 'white'" width="100%" tile elevation="0" active-class="no-active" x-large :ripple="false">공지사항</v-btn></v-flex>
                            <v-flex xs6 class="v-btn px-0"><v-btn to="/center/faqs"          :color="-1 < $route.path.indexOf('/center/faqs')          ? 'primary': 'white'" width="100%" tile elevation="0" active-class="no-active" x-large :ripple="false">자주묻는질문</v-btn></v-flex>
                            <v-flex xs4 class="v-btn px-0"><v-btn to="/center/questions"     :color="-1 < $route.path.indexOf('/center/questions')     ? 'primary': 'white'" width="100%" tile elevation="0" active-class="no-active" x-large :ripple="false">1:1 상담문의</v-btn></v-flex>
                        </v-btn-toggle>
                    </v-card-text> -->
                </v-card>

                <v-responsive width="100vw" max-width="1230px" class="justify-center align-center mx-auto sub-container__new">
                    <slot />
                </v-responsive>
            </v-responsive>
        </v-layout>
    </default-template>
</template>
<script>
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
    components: {
        DefaultTemplate,
    },
};
</script>

<style scoped>
.v-btn-toggle >>> .v-btn {
    opacity: 1 !important;
}
.sub-visual-container {
    overflow: hidden;
    padding: 60px 0;
    height: 350px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-animation: visualFadeIn 0.4s ease-in-out 0.1s forwards;
    animation: visualFadeIn 0.4s ease-in-out 0.1s forwards;
    opacity: 0;
}
.sub-visual-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    padding: 0px 20px;
}
.sub-visual-container .sub-visual--title {
    z-index: 2;
    position: relative;
    line-height: 1;
    text-align: center;
    font-size: 42px;
    font-weight: 700;
    color: #fff;
    transition: all 0.3s ease;
}
.sub-visual-container .sub-visual--title small {
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
    color: #bcc5df;
    font-weight: 300;
}
.sub-visual-container p {
    font-size: 16px;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 15px;
    transition: all 0.3s ease;
    margin-bottom: 0px;
    text-align: center;
}

.contents {
    padding: 120px 0px;
}

.tit {
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.4;
}

.tit strong {
    font-weight: 900;
}

.sub-container__new {
    padding: 110px 0;
}

.sv1 {
    background-image: url("/images/ticketmania/subvisual/sv1.jpg");
}
.sv2 {
    background-image: url("/images/ticketmania/subvisual/sv3.jpg");
}
.sv3 {
    background-image: url("/images/ticketmania/subvisual/sv2.jpg");
}
.sv4 {
    background-image: url("/images/ticketmania/subvisual/sv4.jpg");
}
.sv5 {
    background-image: url("/images/ticketmania/subvisual/sv5.jpg");
}
@-webkit-keyframes visualFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes visualFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@media (max-width: 1200px) {
    .sub-visual-container {
        padding: 50px 0px;
    }
    /* .sub-visual-wrapper {margin-top:50px;} */
}
@media (max-width: 1024px) {
    .sub-visual-container {
        padding: 0;
        height: 240px;
        background-size: cover;
    }
    .sub-visual-container .sub-visual--title {
        font-size: 34px;
    }
    .sub-visual-container .sub-visual--title small {
        font-size: 13px;
    }

    /* .sub-visual-wrapper {margin-top:60px;} */
}
@media (max-width: 768px) {
    /* .sub-visual-wrapper {margin-top:55px;} */
    .sub-visual-container {
        padding: 0;
        height: 160px;
    }
    .sub-visual-container .sub-visual--title {
        font-size: 24px;
    }
    .sub-visual-container .sub-visual--title small {
        margin-bottom: 10px;
        font-size: 11px;
    }
    .sub-visual-container p {
        margin-top: 10px;
    }
    .contents {
        padding: 80px 0px;
    }
}
</style>
